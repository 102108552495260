<template>
  <Layout>
    <div class="bsat__container my-sm-10">
      <!-- <v-tabs
        class="bsat__tabs ml-sm-n5"
        background-color="transparent"
        hide-slider
        color="inactive_grey"
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
          class="mr-4 mr-sm-8"
          :style="
            $vuetify.breakpoint.xsOnly ? 'min-width: 90px' : 'min-width: 160px'
          "
        >
          {{ $t(item.tab) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="mb-12">
        <v-tab-item disabled v-for="item in items" :key="item.tab">
        </v-tab-item>
      </v-tabs-items> -->

      <a
        @click="$router.go(-1)"
        class="bsat__btn dark-text px-0 font-weight-bold mb-4"
        style="font-size: 16px !important"
      >
        <v-icon left color="dark">mdi-keyboard-backspace</v-icon>
        {{ $t("backToPhase3") }}
      </a>

      <div
        class="light_green-text bsat__title font-weight-bold mb-4 pt-4 pt-sm-9"
      >
        {{ $t("title") }}
      </div>

      <section>
        <div class="bsat__subtitle mb-2">{{ $t("section1.title") }}</div>
        <div class="bsat__paragraph">{{ $t("section1.paragraph") }}</div>
        <ReadMore>
          <div class="bsat__paragraph">{{ $t("section1.paragraph2") }}</div>
          <div class="bsat__paragraph">{{ $t("section1.paragraph3") }}</div>
          <div class="bsat__paragraph">{{ $t("section1.paragraph4") }}</div>
        </ReadMore>
      </section>

      <section>
        <div class="bsat__subtitle mb-2">{{ $t("section2.title") }}</div>
        <a :href="policyOverviewFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>

      <section>
        <div class="bsat__subtitle mb-2">{{ $t("section3.title") }}</div>
        <ul>
          <li>
            <v-card flat class="border">
              <v-row class="mx-3 py-3" align="center">
                <i18n
                  path="section3.paragraph.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{ $t("section3.paragraph.title") }}</strong>
                  </template>
                </i18n>
              </v-row>
            </v-card>
          </li>
          <ReadMore>
            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <i18n
                    path="section3.paragraph2.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{ $t("section3.paragraph2.title") }}</strong>
                    </template>
                  </i18n>
                </v-row>
              </v-card>
            </li>

            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <i18n
                    path="section3.paragraph3.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{ $t("section3.paragraph3.title") }}</strong>
                    </template>
                  </i18n>
                </v-row>
              </v-card>
            </li>

            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <i18n
                    path="section3.paragraph4.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{ $t("section3.paragraph4.title") }}</strong>
                    </template>
                  </i18n>
                </v-row>
              </v-card>
            </li>

            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <i18n
                    path="section3.paragraph5.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{ $t("section3.paragraph5.title") }}</strong>
                    </template>
                  </i18n>
                </v-row>
              </v-card>
            </li>
          </ReadMore>
        </ul>
      </section>

      <section>
        <div class="bsat__subtitle mb-4">{{ $t("section4.title") }}</div>

        <ul>
          <li>
            <v-card flat class="border mb-5">
              <v-row class="mx-3 py-3" align="center">
                <div class="bsat__paragraph">
                  {{ $t("section4.paragraph") }}
                </div>
              </v-row>
            </v-card>
          </li>
          <ReadMore>
            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <div class="bsat__paragraph">
                    {{ $t("section4.paragraph2") }}
                  </div>
                </v-row>
              </v-card>
            </li>

            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <div class="bsat__paragraph">
                    {{ $t("section4.paragraph3") }}
                  </div>
                </v-row>
              </v-card>
            </li>

            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <div class="bsat__paragraph">
                    {{ $t("section4.paragraph4") }}
                  </div>
                </v-row>
              </v-card>
            </li>

            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <div class="bsat__paragraph">
                    {{ $t("section4.paragraph5") }}
                  </div>
                </v-row>
              </v-card>
            </li>

            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <div class="bsat__paragraph">
                    {{ $t("section4.paragraph6") }}
                  </div>
                </v-row>
              </v-card>
            </li>

            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <div class="bsat__paragraph">
                    {{ $t("section4.paragraph7") }}
                  </div>
                </v-row>
              </v-card>
            </li>

            <li>
              <v-card flat class="border mb-5">
                <v-row class="mx-3 py-3" align="center">
                  <div class="bsat__paragraph">
                    {{ $t("section4.paragraph8") }}
                  </div>
                </v-row>
              </v-card>
            </li>
          </ReadMore>
        </ul>
      </section>

      <section>
        <div class="bsat__subtitle mt-4 mb-2">{{ $t("section5.title") }}</div>
        <div class="bsat__paragraph mb-2">{{ $t("section5.paragraph") }}</div>
        <a :href="policyExamplesFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>

        <SelectedExamples></SelectedExamples>
      </section>

      <section>
        <div class="bsat__subtitle mt-4 mb-2">{{ $t("section6.title") }}</div>
        <div class="bsat__paragraph mb-2">{{ $t("section6.paragraph") }}</div>
        <a :href="createPolicyExampleFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>

      <section>
        <div class="bsat__subtitle mt-4 mb-2">{{ $t("section7.title") }}</div>
        <a :href="generalBarriersFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>

      <section>
        <div class="bsat__subtitle mt-4 mb-2">{{ $t("section8.title") }}</div>
        <a :href="recommendationsFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>
    </div>
  </Layout>
</template>

<i18n>
{
  "en": {
    "backToPhase3": "Back",
    "section1": {
      "paragraph": "Policy instruments can be organised in 3 main groups namely regulation, economic instruments and other softer instruments such as voluntary, information and advice sharing, market based signalling and other more strategic or vision development instruments. The most common is the regulation which is a command-and-control approach using obligatory standards and licenses that require people/companies/market players to change their behaviour and punishes them if they are detected to be non-compliant. Economic instruments include price incentives (taxes, subsidies, feed-in premium), but also quantity constraints (tradable) quota, tariff rate quota), and charges.",
      "paragraph2": "These instruments give people incentives to voluntary (e.g. based on their own rational cost-benefit calculations) or obligatory taxes to bring about behavioural change. All these types of instruments are needed when a good policy framework needs to be developed for supporting and guiding the development of the bioeconomy. In all these categories there are policies that influence on the biomass supply, the logistics of bringing the biomass to the conversion and processing installation, the biomass conversion process itself, the distribution of the bioeconomy products and the circular end use and markets. ",
      "paragraph3": "Beside policies that impact on the full biomass chain there are also policies needed that influence the wider environment in which bioeconomy activities function such as the research and policy enabling environment, food and industrial environment, business services, consumer preferences and behaviour. ",
      "paragraph4": "Finally, there are also many policy instruments that address the relation between the bio-based economy activities and the environmental and socio-economic context and impacts. So, the bioeconomy is a wide concept that can be influenced directly and indirectly through policies impacting on many different aspects of the bioeconomy.",
      "title": "1. General introduction of policy in the bioeconomy field"
    },
    "section2": {
      "title": "2. EU Policy overview in the area of Bioeconomy"
    },
    "section3": {
      "paragraph": {
        "text": "{title} The sustainable mobilisation of sufficient and good quality biomass is essential to build the bio-based economy. Obstacles are among others low cooperation of farmers and foresters, absence of whole-year availability of the biomass (seasonallity), uncertain provenance, challenging logistics, low quality and sustainability. ",
        "title": "Biomass availability and mobilisation:"
      },
      "paragraph2": {
        "text": "{title} Acceptance by the public is crucial to make sure bio-based products are taken up by the market. Problems are public’s resistance to change, lack of consumer knowledge and confidence in product quality. ",
        "title": "Lack of public acceptance and awareness:"
      },
      "paragraph3": {
        "text": "{title} Developing a bio-based economy requires a shift in the whole value chain, from producers to consumers. This shift is not easy to make, given the current market mechanisms, for instance the price competition from the petrochemistry. ",
        "title": "Lack of supporting market mechanisms:"
      },
      "paragraph4": {
        "text": "{title} Policies often miss clear goals and ways to measure and evaluate progress in meeting policy targets. The policy documents are described in a strategic but qualitative way and rarely include indicators to monitor the progress of the bioeconomy development.",
        "title": "Vague goals and no operationalization:"
      },
      "paragraph5": {
        "text": "{title} Long term vision and policy continuity are needed to build up investor confidence and to catalyse investment.",
        "title": "Timeframe of policy is uncertain:"
      },
      "title": "3. Brief tips for policy analysis in the field of bioeconomy"
    },
    "section4": {
      "paragraph": "The mobilisation of biomass is an important key for further deployment of the bio-based economy. A good understanding needs to be created of the unutilized biomass potential in agriculture, forestry and industry.  The advantage of industry by-products and residues is that they are already available at a central location.",
      "paragraph2": "Commodities from biomass have to be created. They have the advantage of being fully tradable, of stable quality, complying with storage facilities, with shipping and conversion processes. As a result, contracting is easier, markets open faster and more options to finance become available.",
      "paragraph3": "Established governance mechanisms support supply and demand side policy instruments, create policies for innovation, align principles of different policies, prioritise thematic areas or values chains/cycles, take decisions on investments.",
      "paragraph4": "General support on behalf of existing policy framework Provides a stable regulatory framework while remaining neutral concerning choices of technologies and promoting competition both with existing technologies and other sectors.  A supportive policy framework is also able to bring bio-based economy closer to society.",
      "paragraph5": "Follow the EU’s bioeconomy and circularity strategies as much as possible as they already build on established policy frameworks and give solid guidance taking account of policy integration needs. ",
      "paragraph6": "The goals of the bioeconomy strategy can be achieved only by tackling existing policy fragmentation, engaging the civil society to a greater extent and putting in place the national and regional strategies. For example there are goals in EU regulation and priorities that conflict with national goals when being transposed, implemented and enforced by member states (Aggestam et al., 2017). EU policies like the Common Agricultural Policy leave responsibility to the member states to transpose policies into national regulation, which results in differences in terms of the level playing field for actors in the bioeconomy: the implementation of policy also depends on the different interests and political positions of stakeholders.",
      "paragraph7": "The alignment of principles of sustainable bioeconomy with principles of circular economy would involve systemic approaches across sectors leading to optimized value networks and minimized losses and waste.",
      "paragraph8": "Close cooperation and adaptation of the new research outcomes, new innovative business opportunities, in (existing) BBE networks and platforms such as the BBE-JU, BIC, and BioEast in Central and Eastern European countries. Countries and regions have to use all their ambitions to benefit equally from participation in different EU funded programs by matching bottom-up initiatives with top-down processes. ",
      "title": "4. Brief tips of policies as bioeconomy movers"
    },
    "section5": {
      "paragraph": "Consult this table summarizing the main features of the 10 good policy examples selected. Download the dossier containing full information about these examples and look for the factsheet you are interested in",
      "title": "5. Selected 10 good policy examples"
    },
    "section6": {
      "paragraph": "Download this template for collecting and structuring the required information",
      "title": "6. Create your good policy in your region"
    },
    "section7": {
      "title": "7. General barriers and opportunities for policies in the bioeconomy field"
    },
    "section8": {
      "title": "8. Recommendations for the development of tailor-made policy instruments to boost bioeconomy trends"
    },
    "title": "Repository of Supporting documents and tools for regional policies in the field of bioeconomy"
  }
}
</i18n>

<script>
import Layout from "@/layouts/default";
import ReadMore from "@/components/ReadMore";
export default {
  name: "RegionalPolicies",
  metaInfo: {
    title:
      "Repository of Supporting documents and tools for regional policies in the field of bioeconomy."
  },
  components: {
    Layout,
    ReadMore,
    SelectedExamples: () => import("@/components/SelectedExamples")
  },
  data() {
    return {
      policyOverviewFile: require("@/assets/files/regionalPolicies/RP1_EU_policy_instruments_for_bioeconomy_development_and_link_to_wider_sustainability_ambitions.docx")
        .default,
      policyExamplesFile: require("@/assets/files/regionalPolicies/RP2_Policy_Factsheet_1_Austrian_landfill_tax_and_Waste_Prevention_Programmes.docx")
        .default,
      createPolicyExampleFile: require("@/assets/files/regionalPolicies/RP3_Create_your_good_policy_in_your_region.docx")
        .default,
      generalBarriersFile: require("@/assets/files/regionalPolicies/RP4_General_barriers_and_opportunities_for_policies_in_bioeconomy.docx")
        .default,
      recommendationsFile: require("@/assets/files/regionalPolicies/RP5_Recommendations_for_the_development_of_tailor_made_policy_instruments_to_boost_bioeconomy.docx")
        .default,
      tab: null,
      items: [
        { tab: "about" },
        { tab: "phase1" },
        { tab: "phase2" },
        { tab: "phase3" },
        { tab: "phase4" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/main";
.border {
  border: 1px solid $light_green;
}
.bsat__tabs {
  .v-tab {
    font-family: $font-family;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    color: $inactive_grey !important;
  }

  .v-tab .v-tab--active {
    color: $inactive_grey;
    background: none;
  }
}
</style>
